<template>
  <div id="content">
    <!-- <div class="snow">
      <div></div>
    </div> -->
    <div class="content_root_container">
      <div class="content_root_container__item">
        <TopBar @switch-hide-event="changeLeftBarState" />
      </div>
      <div
        v-if="leftBar.allowContent.length > 0"
        class="content_root_container__item main_container"
      >
        <div
          class="main_container__item left_bar"
          :class="leftBar.smallMode ? 'left_bar_small' : ''"
        >
          <LeftBar
            :smallMode="leftBar.smallMode"
            :defaultTab="this.$route.name.toLowerCase()"
            :allowContent="leftBar.allowContent"
          />
        </div>
        <div
          class="main_container__item content"
          :class="leftBar.smallMode ? 'content_large' : ''"
        >
          <div class="content_wrapper">
            <Transition name="slide" mode="out-in">
              <component :is="getComponent"></component>
            </Transition>
          </div>
        </div>
      </div>
      <div v-else class="no_rights_container">
        Обратитесь к администратору для получения прав
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import LeftBar from "@/components/LeftBar.vue";
import { defineAsyncComponent } from "vue";
import { logout } from "@/auth";

export default {
  components: {
    TopBar,
    LeftBar,
  },
  data() {
    return {
      leftBar: {
        smallMode: true,
        allowContent: [],
      },
    };
  },
  methods: {
    logout,
    // hide left nav bar
    changeLeftBarState() {
      this.leftBar.smallMode = !this.leftBar.smallMode;
    },
  },
  computed: {
    getComponent() {
      if (this.$route.name) {
        return defineAsyncComponent(() => import(`./${this.$route.name}.vue`));
      }
      return defineAsyncComponent(() => import(`./Terminals.vue`));
    },
  },

  mounted() {
    // update rights if not not recieved if previos versions
    if (this.$store.getters.USER_RIGHTS === null) {
      logout(this);
    } else {
      this.leftBar.allowContent = this.$store.getters.USER_RIGHTS;
    }
    
  },
};
</script>

<style lang="less">
@import "../assets/styles/colors.less";

.no_rights_container {
  display: flex;
  justify-content: center;
  margin-top: 20%;
  font-size: 35px;
  color: @font-contranst-color;
}

.slide-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.slide-leave-active {
  transition: opacity 0.3s ease-out;
}

.slide-enter-from {
  opacity: 0;
}
.slide-enter-to {
  opacity: 1;
  // transform: scale(1.1);
}
.slide-leave-from {
  opacity: 1;
}
.slide-leave-to {
  opacity: 0;
}
.content_root_container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.main_container {
  display: flex;
  // position: fixed;
  height: 100%;
  width: 100%;
}

.content {
  background-color: #ffffff;
  width: calc(100% - 206px);
  height: 100%;
  transition: 0.3s;
  .content_wrapper {
    padding: 20px 20px 0px 0px;
  }

  @media (max-width: 460px) {
    &.content_large {
      width: 100%;
      transition: 0.1s;
      transition-property: left;
    }
    width: calc(100% - 57px);
  }
  @media (min-width: 461px) {
    &.content_large {
      transition: 0.1s;
      width: calc(100% - 80px);
      transition-property: left;
    }
  }
}

.left_bar {
  transition: 0.3s;
  @media (max-width: 460px) {
    width: 67px;
    margin-left: -10px;
    &.left_bar_small {
      display: none;
    }
  }
  @media (min-width: 461px) {
    width: 200px;
    &.left_bar_small {
      width: 79px;
    }
  }
}

body {
  background-color: #fafafa;
  margin: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

// .snow {
//   width: 100%;
//   height: 100px;
//   border: 1px solid rgba(255, 255, 255, 0.1);
//   background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.3%3Bfill:%23FFFFFF%3B%7D.st3%7Bopacity:0.1%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st1' cx='5' cy='8' r='1'/%3E%3Ccircle class='st1' cx='38' cy='3' r='1'/%3E%3Ccircle class='st1' cx='12' cy='4' r='1'/%3E%3Ccircle class='st1' cx='16' cy='16' r='1'/%3E%3Ccircle class='st1' cx='47' cy='46' r='1'/%3E%3Ccircle class='st1' cx='32' cy='10' r='1'/%3E%3Ccircle class='st1' cx='3' cy='46' r='1'/%3E%3Ccircle class='st1' cx='45' cy='13' r='1'/%3E%3Ccircle class='st1' cx='10' cy='28' r='1'/%3E%3Ccircle class='st1' cx='22' cy='35' r='1'/%3E%3Ccircle class='st1' cx='3' cy='21' r='1'/%3E%3Ccircle class='st1' cx='26' cy='20' r='1'/%3E%3Ccircle class='st1' cx='30' cy='45' r='1'/%3E%3Ccircle class='st1' cx='15' cy='45' r='1'/%3E%3Ccircle class='st1' cx='34' cy='36' r='1'/%3E%3Ccircle class='st1' cx='41' cy='32' r='1'/%3E%3C/svg%3E");
//   background-position: 0px 0px;
//   animation: animatedBackground 10s linear infinite;
//   position: absolute;
//   pointer-events: none;
// }

// .snow div {
//   width: 100%;
//   height: 90px;
//   background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.7%3Bfill:%23FFFFFF%3B%7D.st3%7Bopacity:0.1%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st3' cx='4' cy='14' r='1'/%3E%3Ccircle class='st3' cx='43' cy='3' r='1'/%3E%3Ccircle class='st3' cx='31' cy='30' r='2'/%3E%3Ccircle class='st3' cx='19' cy='23' r='1'/%3E%3Ccircle class='st3' cx='37' cy='22' r='1'/%3E%3Ccircle class='st3' cx='43' cy='16' r='1'/%3E%3Ccircle class='st3' cx='8' cy='45' r='1'/%3E%3Ccircle class='st3' cx='29' cy='39' r='1'/%3E%3Ccircle class='st3' cx='13' cy='37' r='1'/%3E%3Ccircle class='st3' cx='47' cy='32' r='1'/%3E%3Ccircle class='st3' cx='15' cy='4' r='2'/%3E%3Ccircle class='st3' cx='9' cy='27' r='1'/%3E%3Ccircle class='st3' cx='30' cy='9' r='1'/%3E%3Ccircle class='st3' cx='25' cy='15' r='1'/%3E%3Ccircle class='st3' cx='21' cy='45' r='2'/%3E%3Ccircle class='st3' cx='42' cy='45' r='1'/%3E%3C/svg%3E");
//   background-position: 0px 0px;
//   animation: animatedBackground 15s linear infinite;
//   position: absolute;
//   z-index: 100;
// }

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0px 300px;
  }
}
</style>
